// note: importing another model from @models index file creates a circular reference
import { SvgIcon } from './svg-icon';
import { Alert } from './alert';
import { LinkViewModel } from './link-view-model';
import * as moment from 'moment-timezone';

export class AlertViewModel {
  id: number;
  timestamp?: Date;
  timezone?: string;
  title: string;
  icon: SvgIcon;
  isLowPriorityActiveNeedToKnow: boolean;
  highPriority?: boolean;
  displayonDashboard?: boolean;
  pushNotification?: boolean;
  link?: LinkViewModel;
  color?: string;

  constructor(alert: Alert) {
    this.id = alert.id;

    if (alert.timestamp) {
      this.timestamp = moment.utc(alert.timestamp).local().toDate();

      const tz = moment.tz.guess();
      this.timezone = moment.tz(tz).format('z');
    }
    this.title = alert.title;
    this.highPriority = alert.high_priority;
    this.displayonDashboard = alert.display_on_dashboard;
    this.pushNotification = alert.push_notification;
    if (alert.link && Object.keys(alert.link).length) {
      this.link = new LinkViewModel(alert.link);

      if (!this.highPriority) {
        if (alert.link.take_action) {
          this.color =
            (alert.link.take_action.department &&
              alert.link.take_action.department.color) ||
            null;
          if (this.color) {
            this.color = `#${this.color}`;
          }
        } else if (alert.link.need_to_know) {
          this.isLowPriorityActiveNeedToKnow = true;
        }
      }
    }
    this.icon = (this.link && this.link.icon) || new SvgIcon('alerts-urgent');
  }
}
