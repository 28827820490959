import { Injectable } from '@angular/core';
import { environment } from '@env';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APP_CONFIGURATION, SessionStorage } from '@app/index';
import { HttpService } from './http.service';
import { User, UserViewModel } from '@models';
import { LocalStorage } from '@app/utilities';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<UserViewModel>;

  constructor(private httpService: HttpService) {
    this.currentUserSubject = new BehaviorSubject<UserViewModel>(
      JSON.parse(
        SessionStorage.get(APP_CONFIGURATION.SESSION_STORAGE_KEYS.USER)
      )
    );
  }

  public get currentUserValue(): UserViewModel {
    return this.currentUserSubject.value;
  }

  public set currentUser(user: UserViewModel) {
    SessionStorage.set(
      APP_CONFIGURATION.SESSION_STORAGE_KEYS.USER,
      JSON.stringify(user)
    );
    this.currentUserSubject.next(user);
  }

  public clearProxyUser() {
    const user = this.currentUserValue;
    if (user) {
      user.proxyUser = undefined;
      SessionStorage.set(
        APP_CONFIGURATION.SESSION_STORAGE_KEYS.USER,
        JSON.stringify(user)
      );
      this.currentUserSubject.next(user);
    }
  }

  public getCurrentUser(): Observable<UserViewModel> {
    return this.currentUserSubject.asObservable();
  }

  public getUser(): Observable<UserViewModel> {
    return this.httpService.get<User>('/api/v2/auth/user').pipe(
      map((user) => {
        const userVM = new UserViewModel(user);

        const storeId = LocalStorage.get(
          APP_CONFIGURATION.LOCAL_STORAGE_KEYS.STORENUMBER
        );

        if (storeId && user.stores && user.stores.includes(storeId)) {
          userVM.store = storeId;
        }

        SessionStorage.set(
          APP_CONFIGURATION.SESSION_STORAGE_KEYS.USER,
          JSON.stringify(userVM)
        );

        this.currentUserSubject.next(userVM);

        return userVM;
      })
    );
  }

  public login() {
    location.href = `${environment.apiUrl}/api/v1/auth/homeoffice/oauth`;
  }

  public logout() {
    this.httpService.get<any>('/api/v1/auth/logout').subscribe();
    SessionStorage.clear();
    this.currentUserSubject.next(null);
  }
}
