import { DateUtility, ColorUtility } from '@app/utilities';
import { TakeAction } from './takeAction';
import { CalendarEvent, ReoccurTask } from './calendar-event';
import { StoreGroupViewModel } from './storeGroupViewModel';
import { CalendarEventExtendedProps } from './calendar-event-extended-props';
import { APP_CONFIGURATION } from '@app/config/app-configuration';

export class CalendarEventViewModel {
  id: number;
  title: string;
  start: Date;
  end: Date;
  storeGroups: StoreGroupViewModel[];
  isFieldLeadershipContent: boolean;
  isDailyTask: boolean;
  showInWeekView: boolean;
  showInMonthView: boolean;
  showInYearView: boolean;
  borderColor: string;
  backgroundColor: string;
  department: string;
  extendedProps: CalendarEventExtendedProps;

  is_recurring?: boolean;
  frequency?: string;
  recurring_days?: string;
  next_start_date?: Date;
  next_end_date?: Date;
  reoccurs?: ReoccurTask[];

  public static buildFromCalendarEvent(
    event: CalendarEvent
  ): CalendarEventViewModel {
    const viewModel = new CalendarEventViewModel();

    viewModel.id = event.id;
    viewModel.title = event.title;
    viewModel.start = DateUtility.getDate(
      event.start_date,
      APP_CONFIGURATION.YEAR_MONTH_DAY
    );
    // Add a day here to account for FullCalendar's 'exclusive' end date criteria where the end date is not included in the event's range
    viewModel.end = DateUtility.addDays(
      DateUtility.getDate(event.end_date, APP_CONFIGURATION.YEAR_MONTH_DAY),
      1
    );
    viewModel.showInMonthView = event.show_monthview;
    viewModel.showInWeekView = event.show_weekview;
    viewModel.showInYearView = event.show_yearview;
    viewModel.borderColor = `#${event.calendar.primary_color}`;
    viewModel.backgroundColor = `#${event.calendar.secondary_color}`;
    viewModel.department = event.calendar.title;
    viewModel.storeGroups = event.limit_to_store_groups.map((x) => {
      return new StoreGroupViewModel(x);
    });
    viewModel.isFieldLeadershipContent = event.limit_to_field_leaders;

    viewModel.is_recurring = event.is_recurring;
    viewModel.frequency = event.frequency;
    viewModel.recurring_days = event.recurring_days;
    viewModel.reoccurs = event.reoccurs;
    viewModel.next_start_date = event.next_start_date;
    viewModel.next_end_date = event.next_end_date;

    // Extended Props
    viewModel.extendedProps = new CalendarEventExtendedProps(false, null);

    return viewModel;
  }

  public static buildFromDailyTask(event: TakeAction): CalendarEventViewModel {
    const viewModel = new CalendarEventViewModel();

    viewModel.id = event.id;
    viewModel.title = event.title;
    viewModel.start = DateUtility.getDate(
      event.start_date,
      APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
    );
    viewModel.end = DateUtility.addDays(
      DateUtility.getDate(
        event.end_date,
        APP_CONFIGURATION.YEAR_MONTH_DAY_TIME
      ),
      1
    );
    viewModel.showInMonthView = event.show_monthview;
    viewModel.showInWeekView = event.show_weekview;
    viewModel.showInYearView = false;
    viewModel.borderColor = `#${event.department.color}`;
    viewModel.backgroundColor = ColorUtility.convertHexToRGBA(
      event.department.color,
      0.1
    );
    viewModel.department = event.department.title;
    viewModel.storeGroups = event.limit_to_store_groups.map((x) => {
      return new StoreGroupViewModel(x);
    });
    viewModel.isFieldLeadershipContent = event.limit_to_field_leaders;

    viewModel.is_recurring = event.is_recurring;
    viewModel.frequency = event.frequency;
    viewModel.recurring_days = event.recurring_days;
    viewModel.reoccurs = event.reoccurs;
    viewModel.next_start_date = event.next_start_date;
    viewModel.next_end_date = event.next_end_date;

    // Extended Props
    viewModel.extendedProps = new CalendarEventExtendedProps(
      true,
      event.tasks,
      event
    );

    return viewModel;
  }
}

export interface CalendarEventType {
  title: string;
}
